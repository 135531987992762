/* palette colors*/
.grid-report .plot {
  margin: 15px;
  display: none;
  height: 300px !important;
  width: 97% !important;
}
.grid-report .ui-widget {
  border: none !important;
  outline: none !important;
  border-top: 1px solid #d1d8dd !important;
  background-color: #fafbfc !important;
}
.grid-report .show-zero {
  margin: 10px;
  display: none;
}
.column-picker-dialog .column-list {
  margin: 15px 0;
  border: 1px solid #d1d8dd;
}
.column-picker-dialog .column-list .column-list-item {
  padding: 10px;
  border-bottom: 1px solid #d1d8dd;
}
.column-picker-dialog .column-list .column-list-item:last-child {
  border-bottom: none;
}
.column-picker-dialog .column-list .sortable-handle {
  cursor: move;
}
.column-picker-dialog .column-list .sortable-chosen {
  background-color: #fffce7;
}
.column-picker-dialog .column-list .fa-sort {
  margin: 0px 7px;
  margin-top: 9px;
  margin-right: -15px;
}
.column-picker-dialog .column-list .form-control {
  display: inline-block;
  width: 89%;
}
@media (max-width: 767px) {
  .column-picker-dialog .column-list .form-control {
    width: 77%;
  }
}
.column-picker-dialog .column-list .close {
  margin: 2px 7px 0px;
}
.column-picker-dialog .add-btn {
  margin-bottom: 2px;
}
.columns-search {
  margin-bottom: 10px;
}
.report-wrapper {
  overflow: auto;
}
.chart-container {
  border-bottom: 1px solid #d1d8dd;
}
.groupby-box {
  border-bottom: 1px solid #d1d8dd;
  padding: 10px 15px 3px;
}
.groupby-box .remove-groupby {
  margin-top: 6px;
  margin-left: 15px;
}
.groupby-box .groupby-field {
  padding-right: 15px;
  width: calc(100% - 36px);
}
.groupby-box .groupby-field .bcore-control {
  position: relative;
}
@media (min-width: 767px) {
  .groupby-box .row > div[class*="col-sm-"] {
    padding-right: 0px;
  }
  .groupby-field {
    width: 65% !important;
  }
  .groupby-field .bcore-control {
    position: relative;
  }
}
.tag-groupby-area {
  padding: 10px 150px 10px 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
}
.datatable {
  margin-left: -1px;
  margin-top: -1px;
  font-size: 12px;
}
.datatable .bcore-control,
.datatable .form-control {
  margin: 0;
  border-radius: 0px;
  border: none;
  background-color: transparent;
}
.datatable .form-group {
  margin: 0;
}
.datatable .link-btn {
  top: 6px;
}
.datatable select {
  height: 27px;
}
.datatable .checkbox {
  margin: 7px 0 7px 8px;
}
.datatable [data-fieldtype="Color"] .control-input {
  overflow: hidden;
}
.datatable-wrapper .datatable .dt-scrollable {
  min-height: 100px;
  overflow: auto !important;
  width: 100% !important;
  border-top: transparent;
}
.dt-filter.dt-input {
  height: 100%;
}
table td.dt-cell {
  position: relative;
}
.dt-cell__edit {
  padding: 0;
}
.dt-cell__edit input {
  font-size: inherit;
  height: 27px;
}
.dt-header {
  --dt-header-cell-bg: #f7fafc;
  --dt-cell-bg: #f7fafc;
  --dt-text-color: #8d99a6;
}
.dt-header .dt-row[data-is-filter] {
  display: flex !important;
}
.dt-row {
  height: 35px;
}
.dt-row.dt-row-totalRow {
  font-weight: bold;
}
.dt-row.row-update {
  animation: 500ms breathe forwards;
}
.report-action-checkbox {
  margin: 0;
  margin-left: 10px;
}
.report-action-checkbox .checkbox {
  margin: 0;
}
.report-action-checkbox .help-box {
  display: none;
}
.dt-footer {
  border-top: 1px solid var(--border-color);
}
.dt-filter {
  border: 1px solid #ebeff2;
  border-radius: 3px;
  background-color: #fff;
}
.dt-tree-node__toggle + a {
  margin-left: 4px;
}
@keyframes breathe {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #fffdf4;
  }
  100% {
    background-color: transparent;
  }
}
.cell-title {
  font-weight: bold;
}
.cell-effort-driven {
  text-align: center;
}
.toggle {
  height: 9px;
  width: 9px;
  display: inline-block;
  visibility: visible;
}
.toggle.expand {
  background: url("/assets/bcore/js/lib/slickgrid/images/expand.gif") no-repeat center center;
}
.toggle.collapse {
  background: url("/assets/bcore/js/lib/slickgrid/images/collapse.gif") no-repeat center center;
}
:root {
  --dt-border-color: #d1d8dd;
  --dt-primary-color: #5292f7;
  --dt-light-bg: #f5f7fa;
  --dt-light-red: #FD8B8B;
  --dt-light-yellow: #fffce7;
  --dt-orange: #ffa00a;
  --dt-text-color: #000000;
  --dt-text-light: #dfe2e5;
  --dt-spacer-1: 0.25rem;
  --dt-spacer-2: 0.5rem;
  --dt-spacer-3: 1rem;
  --dt-border-radius: 3px;
  --dt-cell-bg: #fff;
  --dt-focus-border-width: 2px;
  --dt-selection-highlight-color: #fffce7;
  --dt-selection-highlight-color: var(--dt-light-yellow);
  --dt-toast-message-border: none;
  --dt-header-cell-bg: #fff;
  --dt-header-cell-bg: var(--dt-cell-bg);
}
.datatable *,
.datatable *::after,
.datatable *::before {
  box-sizing: border-box;
}
.datatable {
  position: relative;
  overflow: hidden;
}
.dt-scrollable {
  height: 40vw;
  overflow: auto;
  border-top: 2px solid #d1d8dd;
  border-top: 2px solid var(--dt-border-color);
}
.dt-scrollable--highlight-all {
  background-color: #fffce7;
  background-color: var(--dt-selection-highlight-color);
}
.dt-scrollable__no-data {
  text-align: center;
  padding: 1rem;
  padding: var(--dt-spacer-3);
  border-left: 1px solid #d1d8dd;
  border-left: 1px solid var(--dt-border-color);
  border-right: 1px solid #d1d8dd;
  border-right: 1px solid var(--dt-border-color);
}
.dt-row {
  display: flex;
}
.dt-row--highlight .dt-cell {
  background-color: #fffce7;
  background-color: var(--dt-selection-highlight-color);
}
.dt-row--unhighlight .dt-cell {
  background-color: #fff;
  background-color: var(--dt-cell-bg);
}
.dt-row--hide {
  display: none;
}
.dt-row:last-child:not(.dt-row-filter) {
  border-bottom: 1px solid #d1d8dd;
  border-bottom: 1px solid var(--dt-border-color);
}
.dt-cell {
  border: 1px solid #d1d8dd;
  border: 1px solid var(--dt-border-color);
  border-bottom: none;
  border-right: none;
  position: relative;
  outline: none;
  padding: 0;
  background-color: #fff;
  background-color: var(--dt-cell-bg);
  color: #000000;
  color: var(--dt-text-color);
  /*
     Fix for firefox and Edge
     https://stackoverflow.com/a/16337203
     firefox paints td background over border
    */
  background-clip: padding-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dt-cell__content {
  padding: 0.5rem;
  padding: var(--dt-spacer-2);
  border: 2px solid transparent;
  border: var(--dt-focus-border-width) solid transparent;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dt-cell__edit {
  display: none;
  padding: 0.5rem;
  padding: var(--dt-spacer-2);
  background-color: #fff;
  background-color: var(--dt-cell-bg);
  border: 2px solid #ffa00a;
  border: var(--dt-focus-border-width) solid var(--dt-orange);
  z-index: 1;
  height: 100%;
}
.dt-cell__resize-handle {
  opacity: 0;
  position: absolute;
  right: -3px;
  top: 0;
  width: 5px;
  height: 100%;
  cursor: col-resize;
  z-index: 1;
}
.dt-cell--editing .dt-cell__content {
  display: none;
}
.dt-cell--editing .dt-cell__edit {
  display: block;
}
.dt-cell--focus .dt-cell__content {
  border-color: #5292f7;
  border-color: var(--dt-primary-color);
}
.dt-cell--highlight {
  background-color: #f5f7fa;
  background-color: var(--dt-light-bg);
}
.dt-cell--dragging {
  background-color: #f5f7fa;
  background-color: var(--dt-light-bg);
}
.dt-cell--header {
  background-color: #fff;
  background-color: var(--dt-header-cell-bg);
}
.dt-cell--header:last-child {
  border-right: 1px solid #d1d8dd;
  border-right: 1px solid var(--dt-border-color);
}
.dt-cell--header .dt-cell__content {
  padding-right: 1rem;
  padding-right: var(--dt-spacer-3);
  font-weight: bold;
}
.dt-cell--header:hover .dt-dropdown__toggle {
  opacity: 1;
}
.dt-cell--tree-close .icon-open {
  display: none;
}
.dt-cell--tree-close .icon-close {
  display: flex;
}
.dt-cell:last-child {
  border-right: 1px solid #d1d8dd;
  border-right: 1px solid var(--dt-border-color);
}
.icon-open,
.icon-close {
  width: 16px;
  height: 16px;
}
.icon-open {
  display: flex;
}
.icon-close {
  display: none;
}
.dt-dropdown {
  position: absolute;
  right: 10px;
  display: inline-flex;
  vertical-align: top;
  text-align: left;
  font-weight: normal;
  cursor: pointer;
}
.dt-dropdown__toggle {
  opacity: 0;
  background-color: #fff;
  background-color: var(--dt-header-cell-bg);
}
.dt-dropdown__list {
  position: fixed;
  min-width: 8rem;
  z-index: 1;
  cursor: pointer;
  background-color: #fff;
  background-color: var(--dt-cell-bg);
  border-radius: 3px;
  border-radius: var(--dt-border-radius);
  padding: 0.5rem 0;
  padding: var(--dt-spacer-2) 0;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.dt-dropdown__list-item {
  padding: 0.5rem 1rem;
  padding: var(--dt-spacer-2) var(--dt-spacer-3);
}
.dt-dropdown__list-item:hover {
  background-color: #f5f7fa;
  background-color: var(--dt-light-bg);
}
.dt-dropdown--active .dt-dropdown__list {
  display: block;
}
.dt-tree-node {
  display: flex;
  align-items: center;
  position: relative;
}
.dt-tree-node__toggle {
  display: inline-block;
  cursor: pointer;
  margin-right: 0.2rem;
}
.dt-toast {
  position: absolute;
  bottom: 1rem;
  bottom: var(--dt-spacer-3);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.dt-toast__message {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.8);
  color: #dfe2e5;
  color: var(--dt-text-light);
  border-radius: 3px;
  border-radius: var(--dt-border-radius);
  padding: 0.5rem 1rem;
  padding: var(--dt-spacer-2) var(--dt-spacer-3);
  border: none;
  border: var(--dt-toast-message-border);
}
.dt-input {
  outline: none;
  width: 100%;
  border: none;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
.dt-freeze {
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f5f7fa;
  background-color: var(--dt-light-bg);
  opacity: 0.5;
  font-size: 2em;
}
.dt-freeze__message {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.dt-paste-target {
  position: fixed;
  left: -999em;
}
body.dt-resize {
  cursor: col-resize;
}
